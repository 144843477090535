const versesBucket = [
    `<p>Joël 2:28</p> Après cela, je répandrai mon esprit sur toute chair; Vos fils et vos filles prophétiseront, Vos vieillards auront des songes, Et vos jeunes gens des visions  (LSG)`,
    `<p>Joel 2:28</p> "And it shall come to pass afterward That I will pour out My Spirit on all flesh; Your sons and your daughters shall prophesy, Your old men shall dream dreams, Your young men shall see visions. (NKJV)`,
    `<p>Joël 2:29</p> Même sur les serviteurs et sur les servantes, Dans ces jours-là, je répandrai mon esprit  (LSG)`,
    `<p>Joel 2:29</p> And also on My menservants and on My maidservants I will pour out My Spirit in those days. (NKJV)`,
    `<p>Joël 2:30 </p> Je ferai paraître des prodiges dans les cieux et sur la terre, Du sang, du feu, et des colonnes de fumée  (LSG)`,
    `<p>Joel 2:30</p> "And I will show wonders in the heavens and in the earth: Blood and fire and pillars of smoke. (NKJV)`,
    `<p>Actes 2:1</p> Le jour de la Pentecôte, ils étaient tous ensemble dans le même lieu.  (LSG)`,
    `<p>Acts 2:1</p> When the Day of Pentecost had fully come, they were all with one accord in one place. (NKJV)`,
    `<p>Actes 2:2</p> Tout à coup il vint du ciel un bruit comme celui d'un vent impétueux, et il remplit toute la maison où ils étaient assis.…  (LSG)`,
    `<p>Acts 2:2</p> And suddenly there came a sound from heaven, as of a rushing mighty wind, and it filled the whole house where they were sitting. (NKJV)`,
    `<p>Actes 2:3 </p> Des langues, semblables à des langues de feu, leur apparurent, séparées les unes des autres, et se posèrent sur chacun d'eux.…  (LSG)`,
    `<p>Acts 2:3</p> Then there appeared to them divided tongues, as of fire, and one sat upon each of them. (NKJV)`,
    `<p>Actes 2:4</p> Et ils furent tous remplis du Saint-Esprit, et se mirent à parler en d'autres langues, selon que l'Esprit leur donnait de s'exprimer.…  (LSG)`,
    `<p>Acts 2:4</p> And they were all filled with the Holy Spirit and began to speak with other tongues, as the Spirit gave them utterance. (NKJV)`,
    `<p>Actes 2:17</p> Dans les derniers jours, dit Dieu, je répandrai de mon Esprit sur toute chair; Vos fils et vos filles prophétiseront, Vos jeunes gens auront des visions, Et vos vieillards auront des songes.…  (LSG)`,
    `<p>Acts 2:17</p> "And it shall come to pass in the last days, says God, That I will pour out of My Spirit on all flesh; Your sons and your daughters shall prophesy, Your young men shall see visions, Your old men shall dream dreams. (NKJV)`,
    `<p>Actes 2:18 </p> Oui, sur mes serviteurs et sur mes servantes, Dans ces jours-là, je répandrai de mon Esprit; et ils prophétiseront.…  (LSG)`,
    `<p>Acts 2:18</p> And on My menservants and on My maidservants I will pour out My Spirit in those days; And they shall prophesy. (NKJV)`,
    `<p>Apocalypse 1:10 </p> Je fus ravi en esprit au jour du Seigneur, et j'entendis derrière moi une voix forte, comme le son d'une trompette,  (LSG)`,
    `<p>Revelation 1:10</p> I was in the Spirit on the Lord's Day, and I heard behind me a loud voice, as of a trumpet, (NKJV)`,
    `<p>Apocalypse 1:11</p> qui disait: Ce que tu vois, écris-le dans un livre, et envoie-le aux sept Eglises, à Ephèse, à Smyrne, à Pergame, à Thyatire, à Sardes, à Philadelphie, et à Laodicée.  (LSG)`,
    `<p>Revelation 1:11</p> saying, "I am the Alpha and the Omega, the First and the Last," and, "What you see, write in a book and send it to the seven churches which are in Asia: to Ephesus, to Smyrna, to Pergamos, to Thyatira, to Sardis, to Philadelphia, and to Laodicea." (NKJV)`,
    `<p>Apocalypse 1:12</p> Je me retournai pour connaître quelle était la voix qui me parlait. Et, après m'être retourné, je vis sept chandeliers d'or,  (LSG)`,
    `<p>Revelation 1:12</p> Then I turned to see the voice that spoke with me. And having turned I saw seven golden lampstands, (NKJV)`,
    `<p>Apocalypse 1:13 </p> Et, au milieu des sept chandeliers, quelqu'un qui ressemblait à un fils d'homme, vêtu d'une longue robe, et ayant une ceinture d'or sur la poitrine  (LSG)`,
    `<p>Revelation 1:13</p> and in the midst of the seven lampstands One like the Son of Man, clothed with a garment down to the feet and girded about the chest with a golden band. (NKJV)`,
    `<p>Proverbes 1:23</p> Tournez-vous pour écouter mes réprimandes! Voici, je répandrai sur vous mon esprit, Je vous ferai connaître mes paroles  (LSG)`,
    `<p>Proverbs 1:23</p> Turn at my rebuke; Surely I will pour out my spirit on you; I will make my words known to you. (NKJV)`,
    `<p>Ésaïe 32:15</p> Jusqu'à ce que l'esprit soit répandu d'en haut sur nous, Et que le désert se change en verger, Et que le verger soit considéré comme une forêt.  (LSG)`,
    `<p>Isaiah 32:15</p> Until the Spirit is poured upon us from on high, And the wilderness becomes a fruitful field, And the fruitful field is counted as a forest. (NKJV)`,
    `<p>Ésaïe 44:3</p> Car je répandrai des eaux sur le sol altéré, Et des ruisseaux sur la terre desséchée; Je répandrai mon esprit sur ta race, Et ma bénédiction sur tes rejetons.  (LSG)`,
    `<p>Isaiah 44:3</p> For I will pour water on him who is thirsty, And floods on the dry ground; I will pour My Spirit on your descendants, And My blessing on your offspring; (NKJV)`,
    `<p>Ézéchiel 39:29</p> Et je ne leur cacherai plus ma face, Car je répandrai mon esprit sur la maison d'Israël, Dit le Seigneur, l'Eternel  (LSG)`,
    `<p>Ezekiel 39:29</p> And I will not hide My face from them anymore; for I shall have poured out My Spirit on the house of Israel,' says the Lord GOD." (NKJV)`,
    `<p>Jean 7:39</p> Il dit cela de l'Esprit que devaient recevoir ceux qui croiraient en lui; car l'Esprit n'était pas encore, parce que Jésus n'avait pas encore été glorifié.  (LSG)`,
    `<p>John 7:39</p> But this He spoke concerning the Spirit, whom those believing in Him would receive; for the Holy Spirit was not yet given, because Jesus was not yet glorified. (NKJV)`,
    `<p>Ésaïe 40:5</p> Alors la gloire de l'Eternel sera révélée, Et au même instant toute chair la verra; Car la bouche de l'Eternel a parlé.  (LSG)`,
    `<p>Isaiah 40:5</p> The glory of the LORD shall be revealed, And all flesh shall see it together; For the mouth of the LORD has spoken." (NKJV)`,
    `<p>Ésaïe 49:6</p> Il dit: C'est peu que tu sois mon serviteur Pour relever les tribus de Jacob Et pour ramener les restes d'Israël: Je t'établis pour être la lumière des nations, Pour porter mon salut jusqu'aux extrémités de la terre.  (LSG)`,
    `<p>Isaiah 49:6</p> Indeed He says, "It is too small a thing that You should be My Servant To raise up the tribes of Jacob, And to restore the preserved ones of Israel; I will also give You as a light to the Gentiles, That You should be My salvation to the ends of the earth."' (NKJV)`,
    `<p>Zacharie 12:10</p> Alors je répandrai sur la maison de David et sur les habitants de Jérusalem Un esprit de grâce et de supplication, Et ils tourneront les regards vers moi, celui qu'ils ont percé. Ils pleureront sur lui comme on pleure sur un fils unique, Ils pleureront amèrement sur lui comme on pleure sur un premier-né.  (LSG)`,
    `<p>Zechariah 12:10</p> "And I will pour on the house of David and on the inhabitants of Jerusalem the Spirit of grace and supplication; then they will look on Me whom they pierced. Yes, they will mourn for Him as one mourns for his only son, and grieve for Him as one grieves for a firstborn. (NKJV)`,
    `<p>Luc 3:6</p> Et toute chair verra le salut de Dieu.  (LSG)`,
    `<p>Luke 3:6</p> And all flesh shall see the salvation of God."' (NKJV)`,
    `<p>Actes 10:44</p> Comme Pierre prononçait encore ces mots, le Saint-Esprit descendit sur tous ceux qui écoutaient la parole  (LSG)`,
    `<p>Acts 10:44</p> While Peter was still speaking these words, the Holy Spirit fell upon all those who heard the word. (NKJV)`,
    `<p>Actes 11:15</p> Lorsque je me fus mis à parler, le Saint-Esprit descendit sur eux, comme sur nous au commencement.…  (LSG)`,
    `<p>Acts 11:15</p> And as I began to speak, the Holy Spirit fell upon them, as upon us at the beginning. (NKJV)`,
    `<p>Ézéchiel 36:27</p> Je mettrai mon esprit en vous, et je ferai en sorte que vous suiviez mes ordonnances, et que vous observiez et pratiquiez mes lois.  (LSG)`,
    `<p>Ezekiel 36:27</p> I will put My Spirit within you and cause you to walk in My statutes, and you will keep My judgments and do them. (NKJV)`,
    `<p>Ézéchiel 37:14</p> Je mettrai mon esprit en vous, et vous vivrez; je vous rétablirai dans votre pays, et vous saurez que moi, l'Eternel, j'ai parlé et agi, dit l'Eternel.  (LSG)`,
    `<p>Ezekiel 37:14</p> I will put My Spirit in you, and you shall live, and I will place you in your own land. Then you shall know that I, the LORD, have spoken it and performed it," says the LORD."' (NKJV)`,
    `<p>Ézéchiel 39:29</p> Et je ne leur cacherai plus ma face, Car je répandrai mon esprit sur la maison d'Israël, Dit le Seigneur, l'Eternel.  (LSG)`,
    `<p>Ezekiel 39:29</p> And I will not hide My face from them anymore; for I shall have poured out My Spirit on the house of Israel,' says the Lord GOD." (NKJV)`,
    `<p>Apocalypse 1:19</p> Ecris donc les choses que tu as vues, et celles qui sont, et celles qui doivent arriver après elles,  (LSG)`,
    `<p>Revelation 1:19</p> Write the things which you have seen, and the things which are, and the things which will take place after this. (NKJV)`,
    `<p>Apocalypse 4:1</p> Après cela, je regardai, et voici, une porte était ouverte dans le ciel. La première voix que j'avais entendue, comme le son d'une trompette, et qui me parlait, dit: Monte ici, et je te ferai voir ce qui doit arriver dans la suite.  (LSG)`,
    `<p>Revelation 4:1</p> After these things I looked, and behold, a door standing open in heaven. And the first voice which I heard was like a trumpet speaking with me, saying, "Come up here, and I will show you things which must take place after this." (NKJV)`,
    `<p>Apocalypse 4:2</p> Aussitôt je fus ravi en esprit. Et voici, il y avait un trône dans le ciel, et sur ce trône quelqu'un était assis.  (LSG)`,
    `<p>Revelation 4:2</p> Immediately I was in the Spirit; and behold, a throne set in heaven, and One sat on the throne. (NKJV)`,
    `<p>Apocalypse 17:3</p> Il me transporta en esprit dans un désert. Et je vis une femme assise sur une bête écarlate, pleine de noms de blasphème, ayant sept têtes et dix cornes  (LSG)`,
    `<p>Revelation 17:3</p> So he carried me away in the Spirit into the wilderness. And I saw a woman sitting on a scarlet beast which was full of names of blasphemy, having seven heads and ten horns. (NKJV)`,
    `<p>Apocalypse 21:10</p> Et il me transporta en esprit sur une grande et haute montagne. Et il me montra la ville sainte, Jérusalem, qui descendait du ciel d'auprès de Dieu,  (LSG)`,
    `<p>Revelation 21:10</p> And he carried me away in the Spirit to a great and high mountain, and showed me the great city, the holy Jerusalem, descending out of heaven from God, (NKJV)`,
    `<p>Apocalypse 2:1</p> Ecris à l'ange de l'Eglise d'Ephèse: Voici ce que dit celui qui tient les sept étoiles dans sa main droite, celui qui marche au milieu des sept chandeliers d'or  (LSG)`,
    `<p>Revelation 2:1</p> "To the angel of the church of Ephesus write, "These things says He who holds the seven stars in His right hand, who walks in the midst of the seven golden lampstands: (NKJV)`,
    `<p>Apocalypse 2:8</p> Ecris à l'ange de l'Eglise de Smyrne: Voici ce que dit le premier et le dernier, celui qui était mort, et qui est revenu à la vie:  (LSG)`,
    `<p>Revelation 2:8</p> "And to the angel of the church in Smyrna write, "These things says the First and the Last, who was dead, and came to life: (NKJV)`,
    `<p>Apocalypse 2:18 </p> Ecris à l'ange de l'Eglise de Thyatire: Voici ce que dit le Fils de Dieu, celui qui a les yeux comme une flamme de feu, et dont les pieds sont semblables à de l'airain ardent  (LSG)`,
    `<p>Revelation 2:18</p> "And to the angel of the church in Thyatira write, "These things says the Son of God, who has eyes like a flame of fire, and His feet like fine brass: (NKJV)`,
    `<p>Apocalypse 3:1</p> Ecris à l'ange de l'Eglise de Sardes: Voici ce que dit celui qui a les sept esprits de Dieu et les sept étoiles: Je connais tes oeuvres. Je sais que tu passes pour être vivant, et tu es mort.  (LSG)`,
    `<p>Revelation 3:1</p> "And to the angel of the church in Sardis write, "These things says He who has the seven Spirits of God and the seven stars: "I know your works, that you have a name that you are alive, but you are dead. (NKJV)`,
    `<p>Apocalypse 3:7</p> Ecris à l'ange de l'Eglise de Philadelphie: Voici ce que dit le Saint, le Véritable, celui qui a la clef de David, celui qui ouvre, et personne ne fermera, celui qui ferme, et personne n'ouvrira  (LSG)`,
    `<p>Revelation 3:7</p> "And to the angel of the church in Philadelphia write, "These things says He who is holy, He who is true, "He who has the key of David, He who opens and no one shuts, and shuts and no one opens": (NKJV)`,
    `<p>Apocalypse 3 :14</p> Ecris à l'ange de l'Eglise de Laodicée: Voici ce que dit l'Amen, le témoin fidèle et véritable, le commencement de la création de Dieu:  (LSG)`,
    `<p>Revelation 3 :14</p> "And to the angel of the church of the Laodiceans write, "These things says the Amen, the Faithful and True Witness, the Beginning of the creation of God: (NKJV)`,
    `<p>Actes 10:10</p> Il eut faim, et il voulut manger. Pendant qu'on lui préparait à manger, il tomba en extase.  (LSG)`,
    `<p>Acts 10:10</p> Then he became very hungry and wanted to eat; but while they made ready, he fell into a trance (NKJV)`,
    `<p>Apocalypse 17:3</p> Il me transporta en esprit dans un désert. Et je vis une femme assise sur une bête écarlate, pleine de noms de blasphème, ayant sept têtes et dix cornes  (LSG)`,
    `<p>Revelation 17:3</p> So he carried me away in the Spirit into the wilderness. And I saw a woman sitting on a scarlet beast which was full of names of blasphemy, having seven heads and ten horns. (NKJV)`,
    `<p>Apocalypse 2:5</p> Souviens-toi donc d'où tu es tombé, repens-toi, et pratique tes premières oeuvres; sinon, je viendrai à toi, et j'ôterai ton chandelier de sa place, à moins que tu ne te repentes.  (LSG)`,
    `<p>Revelation 2:5</p> Remember therefore from where you have fallen; repent and do the first works, or else I will come to you quickly and remove your lampstand from its place--unless you repent. (NKJV)`,
    `<p>Apocalypse 2:7 </p> Que celui qui a des oreilles entende ce que l'Esprit dit aux Eglises: A celui qui vaincra je donnerai à manger de l'arbre de vie, qui est dans le paradis de Dieu.  (LSG)`,
    `<p>Revelation 2:7</p> "He who has an ear, let him hear what the Spirit says to the churches. To him who overcomes I will give to eat from the tree of life, which is in the midst of the Paradise of God."' (NKJV)`,
    `<p>Apocalypse 2:10 </p> Ne crains pas ce que tu vas souffrir. Voici, le diable jettera quelques-uns de vous en prison, afin que vous soyez éprouvés, et vous aurez une tribulation de dix jours. Sois fidèle jusqu'à la mort, et je te donnerai la couronne de vie  (LSG)`,
    `<p>Revelation 2:10</p> Do not fear any of those things which you are about to suffer. Indeed, the devil is about to throw some of you into prison, that you may be tested, and you will have tribulation ten days. Be faithful until death, and I will give you the crown of life. (NKJV)`,
    `<p>Apocalypse 2:11</p> Que celui qui a des oreilles entende ce que l'Esprit dit aux Eglises: Celui qui vaincra n'aura pas à souffrir la seconde mort.  (LSG)`,
    `<p>Revelation 2:11</p> "He who has an ear, let him hear what the Spirit says to the churches. He who overcomes shall not be hurt by the second death."' (NKJV)`,
    `<p>Apocalypse 2:16 </p> Repens-toi donc; sinon, je viendrai à toi bientôt, et je les combattrai avec l'épée de ma bouche.…  (LSG)`,
    `<p>Revelation 2:16</p> Repent, or else I will come to you quickly and will fight against them with the sword of My mouth. (NKJV)`,
    `<p>Apocalypse 2:17</p> Que celui qui a des oreilles entende ce que l'Esprit dit aux Eglises: A celui qui vaincra je donnerai de la manne cachée, et je lui donnerai un caillou blanc; et sur ce caillou est écrit un nom nouveau, que personne ne connaît, si ce n'est celui qui le reçoit.  (LSG)`,
    `<p>Revelation 2:17</p> "He who has an ear, let him hear what the Spirit says to the churches. To him who overcomes I will give some of the hidden manna to eat. And I will give him a white stone, and on the stone a new name written which no one knows except him who receives it."' (NKJV)`,
    `<p>Apocalypse 2:25</p> seulement, ce que vous avez, retenez-le jusqu'à ce que je vienne.  (LSG)`,
    `<p>Revelation 2:25</p> But hold fast what you have till I come. (NKJV)`,
    `<p>Apocalypse 2:26</p> A celui qui vaincra, et qui gardera jusqu'à la fin mes oeuvres, je donnerai autorité sur les nations.…  (LSG)`,
    `<p>Revelation 2:26</p> And he who overcomes, and keeps My works until the end, to him I will give power over the nations-- (NKJV)`,
    `<p>Apocalypse 2:27 </p> Il les paîtra avec une verge de fer, comme on brise les vases d'argile, ainsi que moi-même j'en ai reçu le pouvoir de mon Père  (LSG)`,
    `<p>Revelation 2:27</p> "He shall rule them with a rod of iron; They shall be dashed to pieces like the potter's vessels'-- as I also have received from My Father; (NKJV)`,
    `<p>Apocalypse 2:28 </p> Et je lui donnerai l'étoile du matin.  (LSG)`,
    `<p>Revelation 2:28</p> and I will give him the morning star. (NKJV)`,
    `<p>Apocalypse 3:3 </p> Rappelle-toi donc comment tu as reçu et entendu, et garde, et repens-toi. Si tu ne veilles pas, je viendrai comme un voleur, et tu ne sauras pas à quelle heure je viendrai sur toi  (LSG)`,
    `<p>Revelation 3:3</p> Remember therefore how you have received and heard; hold fast and repent. Therefore if you will not watch, I will come upon you as a thief, and you will not know what hour I will come upon you. (NKJV)`,
    `<p>Apocalypse 3:5 </p> Celui qui vaincra sera revêtu ainsi de vêtements blancs; je n'effacerai point son nom du livre de vie, et je confesserai son nom devant mon Père et devant ses anges  (LSG)`,
    `<p>Revelation 3:5</p> He who overcomes shall be clothed in white garments, and I will not blot out his name from the Book of Life; but I will confess his name before My Father and before His angels. (NKJV)`,
    `<p>Apocalypse 3:10</p> Parce que tu as gardé la parole de la persévérance en moi, je te garderai aussi à l'heure de la tentation qui va venir sur le monde entier, pour éprouver les habitants de la terre.  (LSG)`,
    `<p>Revelation 3:10</p> Because you have kept My command to persevere, I also will keep you from the hour of trial which shall come upon the whole world, to test those who dwell on the earth. (NKJV)`,
    `<p>Apocalypse 3:8 </p> Je connais tes oeuvres. Voici, parce que tu as peu de puissance, et que tu as gardé ma parole, et que tu n'as pas renié mon nom, j'ai mis devant toi une porte ouverte, que personne ne peut fermer  (LSG)`,
    `<p>Revelation 3:8</p> "I know your works. See, I have set before you an open door, and no one can shut it; for you have a little strength, have kept My word, and have not denied My name. (NKJV)`,
    `<p>Apocalypse 3:11</p> Je viens bientôt. Retiens ce que tu as, afin que personne ne prenne ta couronne.  (LSG)`,
    `<p>Revelation 3:11</p> Behold, I am coming quickly! Hold fast what you have, that no one may take your crown. (NKJV)`,
    `<p>Apocalypse 3:12</p> Celui qui vaincra, je ferai de lui une colonne dans le temple de mon Dieu, et il n'en sortira plus; j'écrirai sur lui le nom de mon Dieu, et le nom de la ville de mon Dieu, de la nouvelle Jérusalem qui descend du ciel d'auprès de mon Dieu, et mon nom nouveau  (LSG)`,
    `<p>Revelation 3:12</p> He who overcomes, I will make him a pillar in the temple of My God, and he shall go out no more. I will write on him the name of My God and the name of the city of My God, the New Jerusalem, which comes down out of heaven from My God. And I will write on him My new name. (NKJV)`,
    `<p>Apocalypse 3:18</p> je te conseille d'acheter de moi de l'or éprouvé par le feu, afin que tu deviennes riche, et des vêtements blancs, afin que tu sois vêtu et que la honte de ta nudité ne paraisse pas, et un collyre pour oindre tes yeux, afin que tu voies  (LSG)`,
    `<p>Revelation 3:18</p> I counsel you to buy from Me gold refined in the fire, that you may be rich; and white garments, that you may be clothed, that the shame of your nakedness may not be revealed; and anoint your eyes with eye salve, that you may see. (NKJV)`,
    `<p>Apocalypse 3:19</p> Moi, je reprends et je châtie tous ceux que j'aime. Aie donc du zèle, et repens-to  (LSG)`,
    `<p>Revelation 3:19</p> As many as I love, I rebuke and chasten. Therefore be zealous and repent. (NKJV)`,
    `<p>Apocalypse 3:20</p> Voici, je me tiens à la porte, et je frappe. Si quelqu'un entend ma voix et ouvre la porte, j'entrerai chez lui, je souperai avec lui, et lui avec moi.…  (LSG)`,
    `<p>Revelation 3:20</p> Behold, I stand at the door and knock. If anyone hears My voice and opens the door, I will come in to him and dine with him, and he with Me. (NKJV)`,
    `<p>Apocalypse 3:21</p> Celui qui vaincra, je le ferai asseoir avec moi sur mon trône, comme moi j'ai vaincu et me suis assis avec mon Père sur son trône.…  (LSG)`,
    `<p>Revelation 3:21</p> To him who overcomes I will grant to sit with Me on My throne, as I also overcame and sat down with My Father on His throne. (NKJV)`,
    `<p>Apocalypse 22:6</p> Et il me dit: Ces paroles sont certaines et véritables; et le Seigneur, le Dieu des esprits des prophètes, a envoyé son ange pour montrer à ses serviteurs les choses qui doivent arriver bientôt.  (LSG)`,
    `<p>Revelation 22:6</p> Then he said to me, "These words are faithful and true." And the Lord God of the holy prophets sent His angel to show His servants the things which must shortly take place. (NKJV)`,
    `<p>Jean 16:13</p> Quand le consolateur sera venu, l'Esprit de vérité, il vous conduira dans toute la vérité; car il ne parlera pas de lui-même, mais il dira tout ce qu'il aura entendu, et il vous annoncera les choses à venir.  (LSG)`,
    `<p>John 16:13</p> However, when He, the Spirit of truth, has come, He will guide you into all truth; for He will not speak on His own authority, but whatever He hears He will speak; and He will tell you things to come. (NKJV)`,
    `<p>2 Rois 2:11</p> Comme ils continuaient à marcher en parlant, voici, un char de feu et des chevaux de feu les séparèrent l'un de l'autre, et Elie monta au ciel dans un tourbillon  (LSG)`,
    `<p>2 Kings 2:11</p> Then it happened, as they continued on and talked, that suddenly a chariot of fire appeared with horses of fire, and separated the two of them; and Elijah went up by a whirlwind into heaven. (NKJV)`,
    `<p>Ézéchiel 1:1</p> La trentième année, le cinquième jour du quatrième mois, comme j'étais parmi les captifs du fleuve du Kebar, les cieux s'ouvrirent, et j'eus des visions divines.  (LSG)`,
    `<p>Ezekiel 1:1</p> Now it came to pass in the thirtieth year, in the fourth month, on the fifth day of the month, as I was among the captives by the River Chebar, that the heavens were opened and I saw visions of God. (NKJV)`,
    `<p>Ézéchiel 43:5 </p> Alors, l'esprit m'enleva et me transporta dans le parvis intérieur. Et voici, la gloire de l'Eternel remplissait la maison  (LSG)`,
    `<p>Ezekiel 43:5</p> The Spirit lifted me up and brought me into the inner court; and behold, the glory of the LORD filled the temple. (NKJV)`,
    `<p>Zacharie 4:2</p> Il me dit: Que vois-tu? Je répondis: Je regarde, et voici, il y a un chandelier tout d'or, surmonté d'un vase et portant sept lampes, avec sept conduits pour les lampes qui sont au sommet du chandelier  (LSG)`,
    `<p>Zechariah 4:2</p> And he said to me, "What do you see?" So I said, "I am looking, and there is a lampstand of solid gold with a bowl on top of it, and on the stand seven lamps with seven pipes to the seven lamps. (NKJV)`
];
export default versesBucket;